import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import { AxiosResponse } from "axios";
import { handleAxiosCallError } from "../../infrastructure/Utils";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import { useContext } from "react";
import { AppContext } from "../../Store";
import WizardController, { WizardControllerType } from "../../controllers/WizardController";
import SchedulerSearchCreateDto from "../../model/SchedulerSearchCreateDto";
import SpecialtyReadDto from "../../model/SpecialtyReadDto";

export interface SearchLogicalType {
  fetchFilterData: (sessionCode: string) => void;
  updateDoctorsOrLocation: (value: any, doctor: boolean) => void;
  filterDoctorsByLocation: (locations: Array<number>) => void;
  filterLocationByDoctors: (doctors: Array<number>) => void;
  checkWarning: (sessionCode: string) => void;
  fetchSpecializations: (sessionCode?: string, procedureGroupId?: number) => void;
}

export default function SearchLogical(
  state: InitialStateType,
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>,
  appointmentsSearch: SchedulerSearchCreateDto | undefined,
  setAppointmentsSearch: React.Dispatch<React.SetStateAction<any>>
): SearchLogicalType {
  const { showMessage } = useContext(AppContext);
  const { axiosGetSpecialisations, axiosGetWarning, axiosGetProcedureGroupList }: WizardControllerType = WizardController();

  const fetchFilterData = (sessionCode: string) => {
    fetchSpecializations(sessionCode);
    dispatch({ type: actions.PROCEDURE_GROUPS_LOADING, value: true });
    axiosGetProcedureGroupList(sessionCode)
      .then((response) => {
        dispatch({ type: actions.PROCEDURE_GROUPS, value: response.data.data });
      })
      .catch((e) => {
        if (!e.response) {
          dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
        } else {
          handleAxiosCallError(showMessage, e);
        }
      })
      .finally(() => {
        dispatch({ type: actions.PROCEDURE_GROUPS_LOADING, value: false });
      });
  };

  const fetchSpecializations = (sessionCode?: string, procedureGroupId?: number) => {
    dispatch({ type: actions.FILTER_DATA_LOADING, value: true });
    axiosGetSpecialisations(Number(process.env.REACT_APP_SHEDULER_ID), Number(process.env.REACT_APP_PROVIDER_ID), sessionCode, procedureGroupId)
      .then((response: AxiosResponse) => {
        dispatch({ type: actions.SPECIALISATIONS, value: response.data.data });
        if (state?.selectedGroup) {
          if (!response.data.data.some((item: SpecialtyReadDto)=> appointmentsSearch?.specialtyIdList?.includes(item.id))) {
            setAppointmentsSearch({
              ...appointmentsSearch,
              specialtyIdList: [],
            });
          }
        }
      })
      .catch((e) => {
        if (!e.response) {
          dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
        } else {
          handleAxiosCallError(showMessage, e);
        }
      })
      .finally(() => {
        dispatch({ type: actions.FILTER_DATA_LOADING, value: false });
      });
  };

  const checkWarning = (sessionCode: string) => {
    axiosGetWarning(sessionCode)
      .then((response: AxiosResponse) => {
        if (response.data.sadrzaj) dispatch({ type: actions.SET_WARNING_TEXT, value: response.data.sadrzaj });
      })
      .catch((e) => {
        if (!e.response) {
          dispatch({ type: actions.SERVICE_AVAILABILITY, value: false });
        } else {
          handleAxiosCallError(showMessage, e);
        }
      });
  };

  const updateDoctorsOrLocation = (value: any, doctor: boolean) => {
    const newState = appointmentsSearch?.providerList.map((obj) => {
      if (doctor) {
        return { ...obj, doctorList: value };
      } else {
        return { ...obj, locationIdList: value };
      }
    });

    setAppointmentsSearch({
      ...appointmentsSearch!,
      providerList: newState,
    });
  };

  const filterDoctorsByLocation = (locations: Array<number>) => {
    const currentDoctors = state?.doctors;
    if (locations.length === 0) {
      dispatch({ type: actions.DOCTORS_FOR_SELECT, value: state?.doctors });
      return;
    }
    const filteredDoctors = currentDoctors?.filter((item) => {
      const locationIdsInDoctorList = item.doctorLocationList.map((doctorLocation: any) => doctorLocation.location.id);
      return locations.some((id) => locationIdsInDoctorList.includes(id));
    });
    dispatch({ type: actions.DOCTORS_FOR_SELECT, value: filteredDoctors });
  };

  const filterLocationByDoctors = (doctors: Array<number>) => {
    const currentLocations = state?.locations;
    if (doctors.length === 0) {
      dispatch({ type: actions.LOCATIONS_FOR_SELECT, value: state?.locations });
      return;
    }
    const doctorsFromFilter = state?.doctors.filter((doctor) => doctors.includes(doctor.id));
    const locationsOfDoctorsFromFilter: any = doctorsFromFilter.map((doctor) => doctor.doctorLocationList.map((location: any) => location.location.id));
    const allLocationsIds = [].concat(...locationsOfDoctorsFromFilter);
    // @ts-ignore
    const filteredLocations = currentLocations?.filter((location) => allLocationsIds.includes(location.id));
    dispatch({ type: actions.LOCATIONS_FOR_SELECT, value: filteredLocations });
  };

  return { fetchFilterData, updateDoctorsOrLocation, filterDoctorsByLocation, filterLocationByDoctors, checkWarning, fetchSpecializations };
}
