import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import { Dialog } from "primereact/dialog";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import { DATE_FORMAT, numberFormat, TIME_FORMAT } from "../../infrastructure/Utils";
import moment from "moment/moment";

interface ProcessingPaymentDialogPropsType {
  state: InitialStateType;
}

export default function ProcessingPaymentDialog(props: ProcessingPaymentDialogPropsType) {
  const { state } = props;
  const information = state?.appointment?.frontendInformation ?? JSON.parse(localStorage.getItem("paymentInfo")!) ?? null;

  return (
    <Dialog visible={state?.paymentProcessing} onHide={() => {}} header={""} closable={false} className="w-10 sm:w-10 md:w-8 lg:w-5 xl:w-4 text-center processing-payment-popup">
      <i className="pi pi-spin pi-spinner mb-4" />
      <div className="mb-4 processing-payment-popup--title">{Labels.LABEL_PROCESSING_PAYMENT_POPUP_TITLE}</div>
      <div className="processing-payment-popup--text">{Labels.LABEL_PROCESSING_PAYMENT_POPUP_TEXT_1}</div>
      <div className="processing-payment-popup--text">{Labels.LABEL_PROCESSING_PAYMENT_POPUP_TEXT_2}</div>
      {information && (
        <div className="flex flex-row justify-content-center">
          <div className="success-info-box--items col-12">
            <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
              <span className="block md:inline text-left font-semibold">{Labels.LABEL_USLUGA}:</span>
              <span className="block md:inline text-left font-semibold">{information?.procedureName}</span>
            </div>
            <div className="block md:flex flex-row flex-wrap justify-content-between mb-4">
              <span className="block md:inline text-left font-semibold">{Labels.LABEL_AMOUNT}:</span>
              <span className="block md:inline text-left font-semibold">{numberFormat(information.procedurePrice, 2, 2) + " " + information.procedureCurrency}</span>
            </div>
            <div className="block md:flex flex-row flex-wrap justify-content-between mb-4">
              <span className="block md:inline text-left font-semibold">{Labels.LABEL_VREME}:</span>
              <span className="block md:inline text-left font-semibold">
                {moment(information.appointmentStartTime).format(DATE_FORMAT)} {moment(information.appointmentStartTime).format(TIME_FORMAT)} -{" "}
                {moment(information.appointmentEndTime).format(TIME_FORMAT)}{" "}
              </span>
            </div>
            <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
              <span className="block md:inline text-left font-semibold">{Labels.LABEL_LOKACIJA}:</span>
              <span className="block md:inline text-left font-semibold">
                {information.locationName} - {information.locationAddress}
              </span>
            </div>
            {information?.doctorName && (
              <>
                <div className="block md:flex flex-row flex-wrap justify-content-between mb-3">
                  <span className="block md:inline text-left font-semibold">{Labels.LABEL_DOCTOR}:</span>
                  <span className="block md:inline text-left font-semibold">{information.doctorName}</span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Dialog>
  );
}
