import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import AppointmentBox from "../appointment-box/AppointmentBox";
import ObaveznoPolje from "../obavezno-polje/ObaveznoPolje";
import Reaptcha from "reaptcha";
import { CALENDAR_DATE_FORMAT, DATE_FORMAT2, formatDate, setCaptchaLang, addSrLocal } from "../../infrastructure/Utils";
import Locations from "../static-content/Locations";
import Specialization from "../static-content/Specialization";
// @ts-ignore
import PravilaPrivatnosti from "../../../src/infrastructure/documents/pravila.pdf";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import { useBeforeunload } from "react-beforeunload";

interface PatientInfoPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  captchaRef: React.MutableRefObject<any>;
  onVerify: (recaptchaResponse: any) => void;
}

export default function PatientInfo(props: PatientInfoPropsType) {
  const { state, dispatch, invalidFields, setInvalidFields, captchaRef, onVerify } = props;
  const parseValue = (v: any) => (v ? new Date(v) : undefined);
  addSrLocal();

  useBeforeunload(() => "");

  return (
    <div className="patient-info-layout col-12 pt-4 xl:pt-8">
      <div className="flex flex-column xl:flex-row justify-content-between">
        <Locations />
        <div className="col-12 sm:col-12 md:col-12 lg:col-6 patient-info-wrapper">
          <div className="flex justify-content-center">
            <AppointmentBox boxInformation={state.appointment.frontendInformation} showPriceBlock={true} showAppointmentBlock={true} additionalClass={"col-12"} />
          </div>
          <div className="mb-4 ml-5 text-left patient-info-title">{Labels.LABEL_PATIENT_INFO}</div>
          <div className="flex flex-row justify-content-center align-items-start flex-wrap">
            <div className="col-12 md:col-6 md:pl-0 py-0">
              <div className="mb-2">{Labels.LABEL_PLACEHOLDER_FIRSTNAME + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="mb-5 relative">
                <InputText
                  className={"w-full " + (invalidFields?.patientFirstName && "p-invalid")}
                  placeholder={Labels.LABEL_PLACEHOLDER_FIRSTNAME}
                  value={state?.appointment?.appointment?.patientFirstName ?? ""}
                  onChange={(e) => {
                    dispatch({
                      type: actions.APPOINTMENT_CHANGE,
                      appointment: { ...state.appointment!, fieldName: "appointment", value: { ...state?.appointment?.appointment, patientFirstName: e.target.value } },
                    });
                    setInvalidFields((prev) => ({ ...prev, patientFirstName: false }));
                  }}
                />
                {invalidFields?.patientFirstName && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </div>
            </div>
            <div className="col-12 md:col-6 md:pr-0 py-0">
              <div className="mb-5 relative">
                <div className="mb-2">{Labels.LABEL_PLACEHOLDER_LASTNAME + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <InputText
                  className={"w-full " + (invalidFields?.patientLastName && "p-invalid")}
                  placeholder={Labels.LABEL_PLACEHOLDER_LASTNAME}
                  value={state?.appointment?.appointment?.patientLastName ?? ""}
                  onChange={(e) => {
                    dispatch({
                      type: actions.APPOINTMENT_CHANGE,
                      appointment: { ...state.appointment!, fieldName: "appointment", value: { ...state?.appointment?.appointment, patientLastName: e.target.value } },
                    });
                    setInvalidFields((prev) => ({ ...prev, patientLastName: false }));
                  }}
                />
                {invalidFields?.patientLastName && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-content-center align-items-start flex-wrap">
            <div className="col-12 md:col-6 md:pl-0 py-0">
              <div className="mb-5 relative">
                <div className="mb-2">{Labels.LABEL_PLACEHOLDER_DATE_OF_BIRTH}</div>
                <Calendar
                  dateFormat={CALENDAR_DATE_FORMAT}
                  // mask="99.99.9999."
                  showIcon
                  placeholder={Labels.PLACEHOLDER_DATUM_FORMAT}
                  value={parseValue(state?.appointment?.appointment?.patientDateOfBirth)}
                  onChange={(e) => {
                    dispatch({
                      type: actions.APPOINTMENT_CHANGE,
                      appointment: {
                        ...state.appointment!,
                        fieldName: "appointment",
                        value: { ...state?.appointment?.appointment, patientDateOfBirth: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined, DATE_FORMAT2) },
                      },
                    });
                  }}
                  maxDate={new Date()}
                  className="w-full"
                  showButtonBar
                  locale={"sr"}
                  appendTo={document.body}
                />
              </div>
            </div>
            <div className="col-12 md:col-6 md:pr-0 py-0">
              <div className="mb-5 relative">
                <div className="mb-2">{Labels.LABEL_PLACEHOLDER_PHONE + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <InputText
                  className={"w-full " + (invalidFields?.patientPhoneNumber && "p-invalid")}
                  placeholder={Labels.LABEL_PLACEHOLDER_PHONE}
                  value={state?.appointment?.appointment?.patientPhoneNumber ?? ""}
                  onChange={(e) => {
                    dispatch({
                      type: actions.APPOINTMENT_CHANGE,
                      appointment: { ...state.appointment!, fieldName: "appointment", value: { ...state?.appointment?.appointment, patientPhoneNumber: e.target.value } },
                    });
                    setInvalidFields((prev) => ({ ...prev, patientPhoneNumber: false }));
                  }}
                />
                {invalidFields?.patientPhoneNumber && <ObaveznoPolje text={Labels.LABEL_PHONE_NUMBER_NOT_VALID} />}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-content-center align-items-start flex-wrap">
            <div className="col-12 md:col-6 md:pl-0 py-0">
              <div className="mb-5 relative">
                <div className="mb-2">{Labels.LABEL_PLACEHOLDER_EMAIL + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <InputText
                  className={"w-full " + (invalidFields?.patientEmail && "p-invalid")}
                  placeholder={Labels.LABEL_PLACEHOLDER_EMAIL}
                  value={state?.appointment?.appointment?.patientEmail ?? ""}
                  onChange={(e) => {
                    dispatch({
                      type: actions.APPOINTMENT_CHANGE,
                      appointment: { ...state.appointment!, fieldName: "appointment", value: { ...state?.appointment?.appointment, patientEmail: e.target.value } },
                    });
                    setInvalidFields((prev) => ({ ...prev, patientEmail: false }));
                  }}
                />
                {invalidFields?.patientEmail && <ObaveznoPolje text={Labels.LABEL_EMAIL_NOT_VALID} />}
              </div>
            </div>
            <div className="col-12 md:col-6 md:pr-0 py-0">
              <div className="mb-5 relative">
                <div className="mb-2">{Labels.LABEL_PLACEHOLDER_ADDRESS}</div>
                <InputText
                  className="w-full"
                  placeholder={Labels.LABEL_PLACEHOLDER_ADDRESS}
                  value={state?.appointment?.appointment?.patientAddress ?? ""}
                  onChange={(e) => {
                    dispatch({
                      type: actions.APPOINTMENT_CHANGE,
                      appointment: { ...state.appointment!, fieldName: "appointment", value: { ...state?.appointment?.appointment, patientAddress: e.target.value } },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-5 relative flex flex-row justify-content-center align-items-center">
            <Checkbox
              inputId="patient-accept-privacy-policy"
              checked={state?.privacyPolicy}
              onChange={(e) => {
                dispatch({ type: actions.PRIVACY_POLICY, value: e.checked });
                setInvalidFields((prev) => ({ ...prev, patientPrivacyPolicy: false }));
                captchaRef?.current.execute();
              }}
              className={invalidFields?.patientPrivacyPolicy && "p-invalid"}
            />
            <label htmlFor="patient-accept-privacy-policy" className="cursor-pointer p-checkbox-label ml-2">
              {Labels.LABEL_PRIVACY_POLICY_CHECKBOX_1}{" "}
              <a href={PravilaPrivatnosti} target="_blank" rel="noreferrer">
                {Labels.LABEL_PRIVACY_POLICY_CHECKBOX_2}
              </a>
              {Labels.SPECIAL_CHAR_REQUIRED}
            </label>
          </div>
          <div id="captcha_container">
            <Reaptcha sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} onVerify={onVerify} onLoad={() => setCaptchaLang("sr")} size="invisible" />
          </div>
          <div className="text-center mt-4">{Labels.LABEL_REQUIRED_FIELDS_NOTE}</div>
        </div>
        <Specialization />
      </div>
    </div>
  );
}
