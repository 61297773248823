enum actions {
  INCREMENT_STEP = "increment_step",
  DECREMENT_STEP = "decrement_step",
  SPECIALISATIONS = "specialisations",
  PROCEDURES = "procedures",
  PROCEDURES_LOADING = "procedures_loading",
  LOCATIONS = "locations",
  LOCATIONS_FOR_SELECT = "locations_for_select",
  LOCATIONS_LOADING = "locations_loading",
  DOCTORS = "doctors",
  DOCTORS_FOR_SELECT = "doctors_for_select",
  DOCTORS_LOADING = "doctors_loading",
  FILTER_DATA_LOADING = "filter_data_loading",
  SPECIALITIES_LIST = "specialities_list",
  APPOINTMENT_CHANGE = "appointment_change",
  APPOINTMENTS = "appointments",
  APPOINTMENTS_LOADING = "appointments_loading",
  CLEAR_APPOINTMENT = "clear_appointment",
  SERVICES_LOADING = "services_loading",
  SERVICES = "services",
  SERVICE_AVAILABILITY = "service_availability",
  SHOW_DISCLAIMER = "show_disclaimer",
  SHOW_DISCLAIMER_FOOTER = "show_disclaimer_footer",
  CAPTCHA_SUCCESS = "captcha_success",
  SET_REF_ID = "set_ref_id",
  SET_STEP = "set_step",
  RESET = "reset",
  PAYMENT_PROCESSING = "payment_processing",
  PRIVACY_POLICY = "privacy_policy",
  PUBLIC_INTEGRATION_KEY = "payment_integration_key",
  CONDITIONS_AGREEMENT = "conditions_agreement",
  SET_PROVIDER_ID = "set_provider_id",
  NOT_IN_FUNCTION = "not_in_function",
  SET_WARNING_TEXT = "set_warning_text",
  PROCEDURE_GROUPS = "procedure_groups",
  PROCEDURE_GROUPS_LOADING = "procedure_groups_loading",
  SET_SELECTED_GROUP = "set_selected_group",
}

export default actions;
