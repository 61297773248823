const apiBase = process.env.REACT_APP_BACKEND_API_BASE;
const websocketApiBase = process.env.REACT_APP_BACKEND_WEBSOCKET_API_BASE;
const url = apiBase ? apiBase : "http://" + window.location.hostname + ":8080/nadjimed";
const websocketURL = websocketApiBase ? websocketApiBase : "wss://" + window.location.hostname + ":8080/nadjimed";

const Endpoint = {
  SPECIALISATIONS_LIST: url + "/specialty/specialty-list/website",
  LOCATIONS_LIST: url + "/location/location-list",
  DOCTORS_LIST_SPECIALTY: url + "/doctor/doctor-list",
  APPOINTMENT_SEARCH: url + "/scheduler/search",
  PROCEDURE_LIST: url + "/procedure/procedure-list",
  PUBLIC: url + "/public/test",
  ALL_SECURE_RESERVE: url + "/public/allsecure/reserve",
  ALL_SECURE_WEBSOCKET: websocketURL + "/nadjimed-allsecure-user-websocket?uuid=",
  WARNING: url + "/upozorenje",
  SCHEDULER_WEBSITE: url + "/scheduler/website",
  PROCEDURE_GROUP_LIST: url + "/procedure/procedure-list/provider",
};

export default Endpoint;
