import { Accordion, AccordionTab } from "primereact/accordion";
import { ScrollPanel } from "primereact/scrollpanel";
import SpecialtyTimeslotDto from "../../model/SpecialtyTimeslotDto";
import LocationTimeslotDto from "../../model/LocationTimeslotDto";
import TimeslotReadDto from "../../model/TimeslotReadDto";
import { DATE_FORMAT, TIME_FORMAT } from "../../infrastructure/Utils";
import moment from "moment";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import SkeletonAppointments from "../skeleton/SkeletonAppointments";
import AppointmentBox from "../appointment-box/AppointmentBox";
import Locations from "../static-content/Locations";
import Specialization from "../static-content/Specialization";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import { useBeforeunload } from "react-beforeunload";

interface AppointmentsPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>;
}

export default function Appointments(props: AppointmentsPropsType) {
  const { state, dispatch } = props;

  useBeforeunload(() => "");

  const accordionHeaderTemplate = (location: LocationTimeslotDto) => {
    return (
      <div className="flex flex-row flex-wrap p-4 appointment-accordion-header">
        <div className="appointments-count mr-6 ">{location.slots.length + " " + Labels.LABEL_APPOINTMENTS_HEADER_COUNT}</div>
        <div className="appointment-location">
          {location.locationName} - {location.locationAddress}
        </div>
      </div>
    );
  };

  return (
    <div className="appointments-layout col-12 pt-4 xl:pt-8">
      <div className="flex flex-column xl:flex-row justify-content-between">
        <Locations />
        <div className="col-12 xl:col-6 appointments-wrapper">
          <div className="flex justify-content-center">
            <AppointmentBox boxInformation={state.appointment.frontendInformation} additionalClass={"col-12 md:col-6 xl:col-12"} showPriceBlock={true} showAppointmentBlock={false} />
          </div>
          {state?.appointmentsLoading ? (
            <SkeletonAppointments />
          ) : (
            state?.appointments[0]?.specialtyList.map((specialty: SpecialtyTimeslotDto) => (
              <div key={specialty.specialtyId} className="mb-4">
                <div className="specialisation-title text-left ml-5 mb-3">{specialty.specialtyName}</div>
                <Accordion collapseIcon="pi pi-chevron-up" expandIcon="pi pi-chevron-down" activeIndex={0} className="appointments-accordion">
                  {specialty.locationList.map((location: LocationTimeslotDto) => (
                    <AccordionTab key={location.locationId} header={accordionHeaderTemplate(location)} contentClassName="">
                      {specialty.locationList.length > 1 && (
                        <ScrollPanel style={{ maxHeight: "160px" }} className="pl-3">
                          {location.slots.map((slot: TimeslotReadDto) => (
                            <div
                              key={slot.id}
                              className={`flex flex-row flex-wrap py-2 px-1 appointment-item ${state?.appointment?.appointment?.slot === slot.id ? "selected" : ""}`}
                              onClick={() => {
                                dispatch({
                                  type: actions.APPOINTMENT_CHANGE,
                                  appointment: { ...state.appointment!, fieldName: "appointment", value: { provider: { id: Number(process.env.REACT_APP_PROVIDER_ID) }, slot: slot.id } },
                                });
                                dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "doctor", value: { id: slot.doctorList[0].id } } });
                                dispatch({
                                  type: actions.APPOINTMENT_CHANGE,
                                  appointment: {
                                    ...state.appointment!,
                                    fieldName: "frontendInformation",
                                    value: {
                                      ...state?.appointment?.frontendInformation,
                                      doctorName: slot.doctorList[0]?.name,
                                      specialtyId: specialty.specialtyId,
                                      specialtyName: specialty.specialtyName,
                                      locationId: location.locationId,
                                      locationName: location.locationName,
                                      locationAddress: location.locationAddress,
                                      appointmentStartTime: slot.start,
                                      appointmentEndTime: slot.end,
                                      providerLegalEntityName: location.providerLegalEntityName,
                                      pib: location.pib,
                                    },
                                  },
                                });
                                dispatch({ type: actions.PUBLIC_INTEGRATION_KEY, value: location.publicIntegrationKey });
                              }}>
                              <div className="appointment-time mr-5">
                                {moment(slot.start).format(TIME_FORMAT)} - {moment(slot.end).format(TIME_FORMAT)}
                              </div>
                              <div className="appointment-time mr-5">{moment(slot.start).format(DATE_FORMAT)}</div>
                              <div className="appointment-doctor">{slot.doctorList[0]?.name}</div>
                            </div>
                          ))}
                        </ScrollPanel>
                      )}
                      {specialty.locationList.length < 2 && (
                        <div className="pl-3">
                          {location.slots.map((slot: TimeslotReadDto) => (
                            <div
                              key={slot.id}
                              className={`flex flex-row flex-wrap py-2 px-1 appointment-item ${state?.appointment?.appointment?.slot === slot.id ? "selected" : ""}`}
                              onClick={() => {
                                dispatch({
                                  type: actions.APPOINTMENT_CHANGE,
                                  appointment: { ...state.appointment!, fieldName: "appointment", value: { provider: { id: Number(process.env.REACT_APP_PROVIDER_ID) }, slot: slot.id } },
                                });
                                dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "doctor", value: { id: slot.doctorList[0]?.id } } });
                                dispatch({
                                  type: actions.APPOINTMENT_CHANGE,
                                  appointment: {
                                    ...state.appointment!,
                                    fieldName: "frontendInformation",
                                    value: {
                                      ...state?.appointment?.frontendInformation,
                                      doctorName: slot.doctorList[0]?.name,
                                      specialtyId: specialty.specialtyId,
                                      specialtyName: specialty.specialtyName,
                                      locationId: location.locationId,
                                      locationName: location.locationName,
                                      locationAddress: location.locationAddress,
                                      appointmentStartTime: slot.start,
                                      appointmentEndTime: slot.end,
                                      providerLegalEntityName: location.providerLegalEntityName,
                                      pib: location.pib,
                                    },
                                  },
                                });
                                dispatch({ type: actions.PUBLIC_INTEGRATION_KEY, value: location.publicIntegrationKey });
                              }}>
                              <div className="appointment-time mr-5">
                                {moment(slot.start).format(TIME_FORMAT)} - {moment(slot.end).format(TIME_FORMAT)}
                              </div>
                              <div className="appointment-time mr-5">{moment(slot.start).format(DATE_FORMAT)}</div>
                              <div className="appointment-doctor">{slot.doctorList[0]?.name}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </AccordionTab>
                  ))}
                </Accordion>
              </div>
            ))
          )}
        </div>
        <Specialization />
      </div>
    </div>
  );
}
